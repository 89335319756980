import React, { useState } from "react";
import { Container, Dropdown, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import UserAvatar from "./UserAvatar";
import SignUpModal from "../Modals/SignUpmodal";
import SignInModal from "../Modals/SignInModal";

const navItems = [
  { path: "/", text: "Home" },
  // { path: "/start-petition", text: "Start a Petition" },
  { path: "/our-initiative", text: "Our-Initiative" },
  { path: "/our-teams", text: "Our-Team" },
  { path: "/contact", text: "Contact Us" },
];

const Header = () => {
  const [openModalSignup, ssetOpenModalSignUp] = useState(false);
  const [openModalSignIn, ssetOpenModalSignIn] = useState(false);

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="..."
        className=" header pb-0 pt-3"
      >
        <div className="container justify-content-start justify-content-lg-between">
          <Link to="/" className="me-auto me-lg-5 pb-2">
            <img width={250} height={70} className="logo" src="/assets/images/logo.png" alt="..." />
          </Link>
          {/* <div className="d-block d-lg-none">
            <UserAvatar />
          </div> */}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="py-3 py-lg-0">
            <Nav className="mx-auto gap-3 gap-lg-5 text-center">
              {navItems.map((item, index) => (
                <li key={index}>
                  <Nav.Link
                    eventKey={index}
                    href={item.path}
                    className=" pb-2 pb-lg-3"
                  >
                    <Link   className="nav-link-1 pb-2 pb-lg-3" to={item.path}>
                    {item.text}
                    </Link>
                 
                  </Nav.Link>
                </li>
              ))}
            </Nav>
            {/* <Nav className="mt-3 mt-lg-0 flex-row justify-content-center">
              <Nav.Link
                onClick={() => {
                  ssetOpenModalSignIn(true);
                }}
                className="nav-link-2 pb-2 pb-lg-3"
              >
                Sign in
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  ssetOpenModalSignUp(true);
                }}
                className="nav-link-2 pb-2 pb-lg-3"
              >
                Sign up
              </Nav.Link>
            </Nav> */}
          </Navbar.Collapse>
          {/* <div className="d-none d-lg-block">
            <UserAvatar />
          </div> */}
        </div>
        {openModalSignup ? (
          <SignUpModal
            openModal={openModalSignup}
            setOpenModal={ssetOpenModalSignUp}
          />
        ) : null}
        {openModalSignIn ? (
          <SignInModal
            openModal={openModalSignIn}
            setOpenModal={ssetOpenModalSignIn}
          />
        ) : null}
      </Navbar>
    </>
  );
};

export default Header;
