import React from "react";
import { Link, NavLink } from "react-router-dom";
import FacebookIcon from "../icons/FacebookIcon";
import InstagramIcon from "../icons/InstagramIcon";

const navItems = [
  { path: "/", text: "Home" },
  // { path: "/start-petition", text: "Start a Petition" },
  // { path: "/form", text: "Form" },
  { path: "/contact", text: "Contact Us" },
];

const Footer = () => {
  return (
    <footer
      className="footer text-white pt-5 pb-3"
      style={{ backgroundImage: "url(/assets/images/footer-image.png)" }}
    >
      <div className="container mb-3 pb-5">
        <div className="row gy-4">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
            <div>
              <h4 className="mb-2 mb-md-5 fw-semibold">Contact us</h4>
              <div className="text-18px d-flex gap-3">
                <span className="fw-semibold text-light text-opacity-75">
                  Email:
                </span>
                <a href="mailto:ilmkiaawaz@gmail.com" className="footer-link">
                  ilmkiaawaz@gmail.com
                </a>
              </div>
            </div>
          </div>
          <div className="col-6 col-sm-6 col-md-4 col-lg-4">
            <div>
              {/* <h4 className="mb-4 fw-semibold ps-2 ps-md-5">Site map</h4> */}
              <ul className="nav flex-column flex-nowrap gap-3 border-start border-3 border-white border-opacity-10 ps-2 ps-md-5">
                {navItems.map((item, index) => (
                  <li key={index}>
                    <NavLink to={item.path} className="footer-link">
                      {item.text}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-6 col-sm-6 col-md-4 col-lg-4">
            <div>
              <h4 className="mb-4 fw-semibold ps-2 ps-md-5">Follow us</h4>
              <ul className="nav flex-column flex-nowrap gap-4 border-start border-3 border-white border-opacity-10 ps-2 ps-md-5">
                {/* <li>
                  <a
                    href="http://www.facebook.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-link"
                  >
                    <FacebookIcon />
                    <span className="ms-3">Facebook</span>
                  </a>
                </li> */}
                <li>
                  <a
                    href="https://www.instagram.com/ilmkiiaawaz/?igsh=MTVlNnQzeDZxYmJjZw%3D%3D"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-link"
                  >
                    <InstagramIcon />
                    <span className="ms-3">Instagram</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="d-flex flex-wrap gap-3 text-light">
          <p className="mb-0 me-auto">Copyright by Aawaaz.com</p>
          <div>
            This content is neither created nor endorsed by Google. Report
            Abuse - 
            <Link to className="footer-link">
              Terms of Service
            </Link>
             - 
            <Link to className="footer-link">
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
