import React from "react";

const InstagramIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM15.2235 11.1647H25.7765C28.0181 11.1647 29.8353 12.9819 29.8353 15.2235V25.7765C29.8353 28.0181 28.0181 29.8353 25.7765 29.8353H15.2235C12.9819 29.8353 11.1647 28.0181 11.1647 25.7765V15.2235C11.1647 12.9819 12.9819 11.1647 15.2235 11.1647ZM9 15.2235C9 11.7864 11.7864 9 15.2235 9H25.7765C29.2136 9 32 11.7864 32 15.2235V25.7765C32 29.2136 29.2136 32 25.7765 32H15.2235C11.7864 32 9 29.2136 9 25.7765V15.2235ZM23.9048 20.5C23.9048 22.3804 22.3804 23.9048 20.5 23.9048C18.6196 23.9048 17.0952 22.3804 17.0952 20.5C17.0952 18.6196 18.6196 17.0952 20.5 17.0952C22.3804 17.0952 23.9048 18.6196 23.9048 20.5ZM26 20.5C26 23.5376 23.5376 26 20.5 26C17.4624 26 15 23.5376 15 20.5C15 17.4624 17.4624 15 20.5 15C23.5376 15 26 17.4624 26 20.5ZM28 14.5C28 15.3284 27.3284 16 26.5 16C25.6716 16 25 15.3284 25 14.5C25 13.6716 25.6716 13 26.5 13C27.3284 13 28 13.6716 28 14.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default InstagramIcon;
