import React from "react";
import { ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
import ArrowRightIcon from "../icons/ArrowRightIcon";

const SupportSection = () => {
  return (
    <section className="mb-5">
      <div className="container" data-aos="zoom-in" data-aos-duration="1300">
        <div className="text-center mb-5 pb-lg-3">
          <h1 className="mb-1 heading-color fw-medium display-6">
            Support Us Now
          </h1>
          <p className="mb-0 fs-5">
            Support Our Cause to Build a Better Future
          </p>
        </div>
        <div className="max-w-2 mx-auto">
          <div className="card-1 bg-white p-4 p-md-5 d-flex flex-column flex-md-row gap-3 gap-md-5">
            <div className="d-flex flex-md-column gap-3">
              <div className="d-flex align-items-center gap-3">
                <span className="dot-1 rounded-circle bg-secondary" />
                <span className="light-color-1 text-18px">Signatures</span>
              </div>
              <div className="d-flex align-items-center gap-3">
                <span className="dot-1 rounded-circle bg-dark-1" />
                <span className="light-color-1 text-18px">Next Goal</span>
              </div>
            </div>
            <div className="d-flex align-items-center gap-3 flex-fill">
              <b className="fs-3 fw-semibold text-secondary">250</b>
              <ProgressBar
                variant="secondary"
                className="flex-fill bg-dark-1 rounded-pill"
                now={50}
                style={{ height: "1.75rem" }}
              />
              <b className="fs-3 fw-semibold text-dark-1">500</b>
            </div>
          </div>
          <div className="d-flex flex-column flex-sm-row">
            <div className="mt-5 min-w-3 px-3">
              <div className="circle-1 d-none d-md-flex">
                <div className="bg-white rounded-circle text-center position-relative z-1">
                  <h3 className="mb-0 fw-semibold heading-color">250</h3>
                  <small className="heading-color text-capitalize">
                    Signatures
                  </small>
                </div>
              </div>
              <p className="mt-4 pt-2 mb-0 text-18px lh-lg">
                Support Our Cause By Signing This Petition.
              </p>
              <div className="mt-3">
                <Link
                  to="https://www.change.org/p/aawaz-advocating-for-equitable-education-every-child-s-right?recruiter=1317200030&recruited_by_id=56796990-5ad7-11ee-83ae-39517c4dd7aa&utm_source=share_petition&utm_campaign=share_for_starters_page&utm_medium=copylink"
                  className="btn btn-dark border-black border-4 rounded-pill fs-5 px-4 py-3 d-inline-flex align-items-center gap-4"
                >
                  <span>Sign This Petition</span>
                  <span className="icon-arrow">
                    <ArrowRightIcon />
                  </span>
                </Link>
              </div>
            </div>
            <div className="flex-fill margin-top-2">
              <img
                className="h-100 w-100 object-fit-contain"
                src="/assets/images/image-2.png"
                alt=".."
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SupportSection;
