import React, { useState } from "react";
import "./survey.css";
import { Button, Checkbox, Form, Input } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import ArrowRightIcon from "../../components/icons/ArrowRightIcon";
import { Link } from "react-router-dom";
import SubmitModal from "../../components/Modals/SubmitModal";
import ClearModal from "../../components/Modals/ClearModal";
export default function Survey() {
  const [openModal, setOpenModal]= useState(false)
  const [clearmodal, setOpenClarmodal] = useState(false)
  return (
    <div className="ps-3 pe-3">
      <div className="row ">
        <div className="col-4 image-container">
          <img
            className="image-container image-top-survey"
            alt="iamge12survey"
            src="/assets/images/image 22.png"
          />
        </div>
        <div className="col-4 image-container">
          <img
            className="image-container image-top-survey1"
            alt="iamge11survey"
            src="/assets/images/image_2 1.png"
          />
        </div>
        <div className="col-4 image-container">
          <img
            className="image-container image-top-survey"
            alt="iamge13survey"
            src="/assets/images/image 23.png"
          />
        </div>
      </div>
      <div className="text-container-survey">
        <p className="survey-top-text">
          Assessing Equal Access to Education - A Right for Every Child
        </p>
        <p className="sub-text-survey">
          The "Assessing Equal Access to Education - A Right for Every Child"
          survey is an initiative by a dedicated student group named Aawaz. This
          survey aims to comprehensively understand and assess the accessibility
          of education for every child within the domain of their right to
          education. We believe that education is a fundamental right that
          should be available to every child, regardless of their socioeconomic
          status, gender, or geographic location. This survey seeks to gather
          valuable insights from respondents regarding their perspectives on the
          current state of education accessibility, highlighting areas for
          improvement and providing a platform for voices to be heard. Your
          participation will significantly contribute to our understanding and,
          ultimately, our efforts to advocate for a more inclusive and
          accessible education system for every child in Pakistan. Thank you for
          being a part of this vital initiative!
        </p>
      </div>
      <p className="education-acc">Education Accessibility Survey</p>
      <p className="sub-text-survey1">
        Your Voice Matters in Shaping Better Education Access
      </p>
      <p>
        <div className="mt-5" style={{ marginLeft: "30%" }}>
          <Form>
            <div>
              <p className="label-forms">What is your Age?</p>
              <Form.Item name="age ">
                <div className="d-flex flex-column ">
                  <Checkbox>13-16</Checkbox>
                  <Checkbox>16-18</Checkbox>
                  <Checkbox>18-25</Checkbox>
                  <Checkbox>25-40</Checkbox>
                  <Checkbox>45-60</Checkbox>
                  <Checkbox>60+</Checkbox>
                </div>
              </Form.Item>
            </div>
            <div>
              <p className="label-forms">
                Where do you reside? (Note: Pakistan is in South Asia)
              </p>
              <Form.Item name="age ">
                <div className="d-flex flex-column ">
                  <Checkbox>South Asia</Checkbox>
                  <Checkbox>Southeastern Asia</Checkbox>
                  <Checkbox>Central Asia</Checkbox>
                  <Checkbox>East Asia</Checkbox>
                  <Checkbox>Western Asia</Checkbox>
                  <Checkbox>Europe</Checkbox>
                  <Checkbox>North America</Checkbox>
                  <Checkbox>South America</Checkbox>
                  <Checkbox>Africa</Checkbox>
                  <Checkbox>Antarctica</Checkbox>
                  <Checkbox>Australia</Checkbox>
                  <Checkbox>Australia</Checkbox>
                </div>
              </Form.Item>
            </div>
            <div>
              <p className="label-forms">
                Do you believe that every child across the globe has equal
                access to education?
              </p>
              <Form.Item name="age ">
                <div className="d-flex flex-column ">
                  <Checkbox>Yes</Checkbox>
                  <Checkbox>No</Checkbox>
                </div>
              </Form.Item>
            </div>
            <div>
              <p className="label-forms">
                Are you aware of any obstacles that prevent children from
                accessing education? If yes, kindly list them. (If not, type NA)
              </p>
              <Form.Item>
                <Input className="input-ant" name="obstacles" />
              </Form.Item>
            </div>
            <div>
              <p className="label-forms">
                Are you satisfied with the education you are receiving/have
                received?
              </p>
              <Form.Item name="age ">
                <div className="d-flex flex-column ">
                  <Checkbox>Yes</Checkbox>
                  <Checkbox>No</Checkbox>
                  <Checkbox>Almost</Checkbox>
                </div>
              </Form.Item>
            </div>
            <div>
              <p className="label-forms">
                Do you think lack of education causes issues in daily life for
                children?
              </p>
              <Form.Item name="age ">
                <div className="d-flex flex-column ">
                  <Checkbox>Yes</Checkbox>
                  <Checkbox>No</Checkbox>
                  <Checkbox>Sometimes</Checkbox>
                </div>
              </Form.Item>
            </div>
            <div>
              <p className="label-forms">
                Are you aware of the laws that guarantee the right to education
                in your country?
              </p>
              <Form.Item name="age ">
                <div className="d-flex flex-column ">
                  <Checkbox>Yes</Checkbox>
                  <Checkbox>No</Checkbox>
                </div>
              </Form.Item>
            </div>
            <div>
              <p className="label-forms">
                Are you aware of the laws that guarantee the right to education
                in Pakistan?
              </p>
              <Form.Item name="age ">
                <div className="d-flex flex-column ">
                  <Checkbox>Yes</Checkbox>
                  <Checkbox>No</Checkbox>
                </div>
              </Form.Item>
            </div>
            <div>
              <p className="label-forms">
                How would you rate the quality of education in first-world
                countries?
              </p>
              <Form.Item name="age ">
                <div className="d-flex flex-column ">
                  <Checkbox>Excellent</Checkbox>
                  <Checkbox>Good</Checkbox>
                  <Checkbox>Fair</Checkbox>
                  <Checkbox>Poor</Checkbox>
                </div>
              </Form.Item>
            </div>
            <div>
              <p className="label-forms">
                How would you rate the quality of education in third-world
                countries?
              </p>
              <Form.Item name="age ">
                <div className="d-flex flex-column ">
                  <Checkbox>Excellent</Checkbox>
                  <Checkbox>Good</Checkbox>
                  <Checkbox>Fair</Checkbox>
                  <Checkbox>Poor</Checkbox>
                </div>
              </Form.Item>
            </div>
            <div>
              <p className="label-forms">
                Do you believe that gender disparity exists in access to
                education? ?
              </p>
              <Form.Item name="age ">
                <div className="d-flex flex-column ">
                  <Checkbox>Yes</Checkbox>
                  <Checkbox>No</Checkbox>
                </div>
              </Form.Item>
            </div>
            <div>
              <p className="label-forms">
                In your opinion, what is the biggest barrier to receiving
                education?
              </p>
              <Form.Item name="age ">
                <div className="d-flex flex-column ">
                  <Checkbox>Poverty</Checkbox>
                  <Checkbox>Lack of infrastructure</Checkbox>
                  <Checkbox>Cultural norms and traditions</Checkbox>
                  <Checkbox>Gender disparity</Checkbox>
                  <Checkbox>Other</Checkbox>
                </div>
              </Form.Item>
            </div>
            <div>
              <p className="label-forms">
                What do you think is the most crucial aspect of improving the
                quality of education across the globe?
              </p>
              <Form.Item name="age ">
                <div className="d-flex flex-column ">
                  <Checkbox>Building more schools</Checkbox>
                  <Checkbox>Improving teacher training</Checkbox>
                  <Checkbox>Reducing poverty</Checkbox>
                  <Checkbox>Promoting gender equality</Checkbox>
                  <Checkbox>More government funding</Checkbox>
                  <Checkbox>Other</Checkbox>
                </div>
              </Form.Item>
            </div>
            <div>
              <p className="label-forms">
                Have you or someone you know faced any challenges in accessing
                education? If yes, please specify. (If no, type NA)
              </p>
              <Form.Item name="age ">
                <Input className="input-ant"></Input>
              </Form.Item>
            </div>
            <div>
              <p className="label-forms">
                Should education be made compulsory for all children worldwide?
              </p>
              <Form.Item name="age ">
                <div className="d-flex flex-column ">
                  <Checkbox>Yes</Checkbox>
                  <Checkbox>No</Checkbox>
                </div>
              </Form.Item>
            </div>
            <div>
              <p className="label-forms">
                Do you believe that Pakistan's education system adequately
                addresses the needs of children with disabilities?
              </p>
              <Form.Item name="age ">
                <div className="d-flex flex-column ">
                  <Checkbox>Yes</Checkbox>
                  <Checkbox>No</Checkbox>
                </div>
              </Form.Item>
            </div>
            <div>
              <p className="label-forms">
                Should the government provide financial assistance or
                scholarships to children from economically disadvantaged
                backgrounds?
              </p>
              <Form.Item name="age ">
                <div className="d-flex flex-column ">
                  <Checkbox>Yes</Checkbox>
                  <Checkbox>No</Checkbox>
                </div>
              </Form.Item>
            </div>
            <div>
              <p className="label-forms">
                Do you believe that children in your country receive a
                well-rounded education that includes arts, sports, and
                extracurricular activities?
              </p>
              <Form.Item name="age ">
                <div className="d-flex flex-column ">
                  <Checkbox>Yes</Checkbox>
                  <Checkbox>No</Checkbox>
                </div>
              </Form.Item>
            </div>
            <div>
              <p className="label-forms">
                Do you believe that children in Pakistan receive a well-rounded
                education that includes arts, sports, and extracurricular
                activities?
              </p>
              <Form.Item name="age ">
                <div className="d-flex flex-column ">
                  <Checkbox>Yes</Checkbox>
                  <Checkbox>No</Checkbox>
                </div>
              </Form.Item>
            </div>
            <div>
              <p className="label-forms">
                Are you aware of any government initiatives, NGOs, or
                individuals working to improve education in Pakistan or in any
                other country? If yes, kindly list them. (If not, type NA)
              </p>
              <Form.Item name="age ">
                <Input className="input-ant"></Input>
              </Form.Item>
            </div>
            <div>
              <p className="label-forms">
                Would you be willing to actively support initiatives that
                promote the right to education for every child in Pakistan?
              </p>
              <Form.Item name="age ">
                <div className="d-flex flex-column ">
                  <Checkbox>Yes</Checkbox>
                  <Checkbox>No</Checkbox>
                </div>
              </Form.Item>
            </div>
            <div className="row">
              <div className="col-3">
                <Form.Item>
                  <Button onClick={()=>{setOpenModal(true)}} className="surveybtn1 btn btn-danger border-black border-1 rounded-pill fs-5 px-4 py-3 d-inline-flex align-items-center gap-3"> Submit
                  <span className="icon-arrow">
                    <ArrowRightIcon />
                  </span>
                  </Button>
                </Form.Item>
              </div>
              <div className="col-3">
              <Button onClick={()=>{setOpenClarmodal(true)}} className="surveybtn btn btn-dark border-black border-3 rounded-pill fs-5 px-4 py-3 d-inline-flex align-items-center gap-3"> Clear All
                  <span className="icon-arrow">
                    <ArrowRightIcon />
                  </span>
                  </Button>
                </div>
            </div>
          </Form>
          <p className="surveylasttext">
            Never submit passwords through Google Forms.
          </p>
        </div>
      </p>
      {
        openModal ?
        <SubmitModal openModal={openModal} setOpenModal={setOpenModal} />
        : null
      }
         {
        clearmodal ?
        <ClearModal openModal={clearmodal} setOpenModal={setOpenClarmodal} />
        : null
      }
    </div>
  );
}
