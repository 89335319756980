import React, { useState } from "react";
import "../Petition/petition.css";
import { Input } from "antd";
import Lottie from "react-lottie";
import animationData from "../../../src/lottianimations/petitionanimation.json";
import ArrowRightIcon from "../../components/icons/ArrowRightIcon";
import GallerySection from "../../components/sections/GallerySection";
import ThanksModal from "../../components/Modals/ThanksModal";
export default function OurTeams() {
  const [openModal, setOpenModal] = useState(false);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div>
      <div
        style={{
          backgroundImage: "url(/assets/images/contactmainimage.png)",
          height: "40vh",
        }}
        className="cmainimage"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignItems: "center",
          }}
        >
          <h1
            style={{ marginTop: "5rem" }}
            className=" display-4 fw-semibold text-white"
          >
            Our Team
          </h1>
        </div>
      </div>
      <section>
        <div className="container margin-top-1 mt-5 mb-5">
          {/* <h1 className="mb-3 heading-color fw-medium display-6">
            Our Teams
          </h1> */}
          <div className="row ">
            <div
              className="col-md-3 col-sm-6"
              data-aos="fade-right"
              data-aos-duration="1300"
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img
                  width={400}
                  height={250}
                  className=" object-fit-contain"
                  src="/assets/images/team1.jpeg"
                  alt="Previous"
                />
                <h4 className="mb-1 mt-2 heading-color fw-medium">
                  Team Leader
                </h4>
                <p className="mb-2 fs-5">Noor Un Nisa</p>
                <a href = "mailto:noorunnisa428@gmail.com">noorunnisa428@gmail.com</a>
              </div>
            </div>
            <div
              className="col-md-3 col-sm-3"
              data-aos="zoom-in"
              data-aos-duration="1300"
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img
                  width={400}
                  height={250}
                  className=" object-fit-contain"
                  src="/assets/images/team2.jpeg"
                  alt="Previous"
                />
                <h4 className="mb-1 mt-2 heading-color fw-medium">
                  Team Member
                </h4>
                <p className="mb-2 fs-5">Myiesha Javed</p>
                 <a href = "mailto:myiesha.mirza@gmail.com">myiesha.mirza@gmail.com</a>
              </div>
            </div>
            <div
              className="col-md-3 col-sm-3"
              data-aos="zoom-in"
              data-aos-duration="1300"
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img
                  width={400}
                  height={250}
                  className=" object-fit-contain"
                  src="/assets/images/team3.jpeg"
                  alt="Previous"
                />
                <h4 className="mb-1 mt-2 heading-color fw-medium">
                  Team Member
                </h4>
                <p className="mb-2 fs-5">Ashadieeyah Sikandar</p>
                <a href = "mailto:ashadieeyahsikandar@gmail.com">ashadieeyahsikandar@gmail.com</a>
              </div>
            </div>
            <div
              data-aos-duration="1300"
              data-aos="fade-left"
              className="col-md-3 col-sm-3"
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img
                  width={400}
                  height={250}
                  className=" object-fit-contain"
                  src="/assets/images/team4.jpeg"
                  alt="Previous"
                />
                <h4 className="mb-1 mt-2 heading-color fw-medium">
                  Team Member
                </h4>
                <p className="mb-2 fs-5">Rida Saeed</p>
                <a href = "mailto:ridasaeed7b@gmail.com">ridasaeed7b@gmail.com</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
