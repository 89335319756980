import React, { useState } from "react";
import { Button, Form, Modal } from "antd";

import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import ArrowRightIcon from "../icons/ArrowRightIcon";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../../../src/lottianimations/childrenplaying.json";

export default function SubmitModal({ openModal, setOpenModal }) {
  const handleCancel = () => {
    setOpenModal(false);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <Modal
        centered
        width={984}
        open={openModal}
        footer={null}
        closeIcon={<CloseCircleOutlined style={{ color: "#005BAA" }} />}
        //  onOk={() => setOpenModal(false)}
        onCancel={handleCancel}
      >
        <div
          className="submitmodalheader  "
          style={{
            backgroundImage: "url(/assets/images/submitmodalimage.png)",
          }}
        ></div>
        <div className="d-flex justify-content-evenly">
          <div>
            <p className="heading-color fw-medium display-6 mt-4">
              Form Submitted
            </p>
            <p className="fs-5">Your Response has been Recorded</p>
            <div className="mt-5 pt-lg-5">
              <Link
                to="/survey"
                className="btn btn-dark border-black border-4 rounded-pill fs-5 px-4 py-3 d-inline-flex align-items-center gap-3"
              >
                <span
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  Submit another Form
                </span>
                <span className="icon-arrow">
                  <ArrowRightIcon />
                </span>
              </Link>
            </div>
          </div>
          <div>
            <Lottie options={defaultOptions} height={250} width={250} />
          </div>
        </div>
      </Modal>
    </>
  );
}
