import React, { useState } from "react";
import { Button, Form, Input, Modal } from "antd";

import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import ArrowRightIcon from "../icons/ArrowRightIcon";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../../../src/lottianimations/childrenplaying.json";
import TrashModal from "./TrashModal";
import "../../pages/Petition/petition.css";
export default function SignInModal({ openModal, setOpenModal }) {
  const [openmodalclear, setOpenModalclears] = useState(false);
  const handleCancel = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Modal
        centered
        open={openModal}
        footer={null}
        width={1000}
        closeIcon={<CloseCircleOutlined style={{ color: "#005BAA" }} />}
        //  onOk={() => setOpenModal(false)}
        onCancel={handleCancel}
      >
        <div className="d-flex">
          <div className="ms-5">
            <p className="heading-color fw-medium display-6 mt-4">Sign In</p>
            <Input
              className="mt-4 me-2 w-50 input-sign-up"
              placeholder="Name"
            />
            <Input
              className="mt-4 me-2 w-50 input-sign-up"
              placeholder="Email"
            />
            <Input
              className="mt-4 me-2 w-50 input-sign-up"
              placeholder="Password"
            />
            <div className="mt-5">
              <Button className=" ms-2 surveybtn1 btn btn-tranparent border-black border-1 rounded-pill fs-5 px-4 py-3 d-inline-flex align-items-center gap-3">
                {" "}
                Sign In
                <span className="icon-arrow">
                  <ArrowRightIcon />
                </span>
              </Button>
              <Button className="ms-4 surveybtn1 btn btn-tranparent border-black border-1 rounded-pill fs-5 px-4 py-3 d-inline-flex align-items-center gap-3">
                {" "}
                Sign Up
                <span className="icon-arrow">
                  <ArrowRightIcon />
                </span>
              </Button>
            </div>
          </div>
          <div
            className="sdownimage"
            style={{ backgroundImage: "url(/assets/images/signupimage.png)" }}
          >
            <p className=" text-white fs-4 text-center mt-5">
              Use your facebook or Google accounts for Registration
            </p>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <div className="mt-5 pt-lg-5">
                <div
                  style={{ background: "#476CB9" }}
                  className="btn btn-primary border-black border-1 rounded-pill fs-5 px-4 py-3 d-inline-flex align-items-center gap-3"
                >
                  <span>Sign up with</span>
                  <span className="icon-arrow">
                    <img src="/assets/images/ficon.png" />
                  </span>
                </div>
              </div>
              <div className="mt-1 pt-lg-5">
                <div className="btn btn-danger  border-2 rounded-pill fs-5 px-4 py-3 d-inline-flex align-items-center gap-3">
                  <span>Sign up with</span>
                  <span className="icon-arrow">
                    <img src="/assets/images/gicon.png" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {openmodalclear ? (
        <TrashModal
          openModal={openmodalclear}
          setOpenModal={setOpenModalclears}
        />
      ) : null}
    </>
  );
}
