import React from "react";
import HeroSection from "../components/sections/HeroSection";
import SupportSection from "../components/sections/SupportSection";
import GallerySection from "../components/sections/GallerySection";
import EducationSection from "../components/sections/EducationSection";
import UpdatesSection from "../components/sections/UpdatesSection";
import SignThisPetitionSection from "../components/sections/SignThisPetitionSection";

const HomePage = () => {
  return (
    <main>
      <HeroSection />
      <SupportSection />
      <SignThisPetitionSection />
      <GallerySection />
      <EducationSection />
      <UpdatesSection />
    </main>
  );
};

export default HomePage;
