import React, { useCallback, useRef } from "react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const items = [
  {
    imageUrl: "/assets/images/image1.jpeg",
  },
  {
    imageUrl: "/assets/images/image2.jpeg",
  },
  {
    imageUrl: "/assets/images/image3.jpeg",
  },
  {
    imageUrl: "/assets/images/image4.jpeg",
  },
  {
    imageUrl: "/assets/images/image5.jpeg",
  },
  {
    imageUrl: "/assets/images/image6.jpeg",
  },
  {
    imageUrl: "/assets/images/image7.jpeg",
  },
  {
    imageUrl: "/assets/images/image8.jpeg",
  },
  {
    imageUrl: "/assets/images/image9.jpeg",
  },
];

const GallerySection = () => {
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);
  return (
    <section className="pt-4 pb-5">
      <div className="container">
        <div data-aos="fade-right" data-aos-duration="1300">
          <div className="position-relative">
            <button
              type="button"
              className="btn p-0 border-0 d-none d-sm-block nav-arrow nav-left"
              onClick={handlePrev}
            >
              <img
                className="h-100 w-100 object-fit-contain"
                src="/assets/images/nav-arrow.gif"
                alt="Previous"
              />
            </button>
            <button
              type="button"
              className="btn p-0 border-0 d-none d-sm-block nav-arrow nav-right"
              onClick={handleNext}
            >
              <img
                className="h-100 w-100 object-fit-contain"
                src="/assets/images/nav-arrow.gif"
                alt="Next"
              />
            </button>
            <Swiper
              spaceBetween={20}
              slidesPerView="auto"
              pagination={{ clickable: true }}
              autoplay={{ delay: 3000 }}
              modules={[Navigation, Pagination, Autoplay]}
              breakpoints={{
                640: {
                  slidesPerView: "auto",
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: "auto",
                  spaceBetween: 15,
                  pagination: false,
                },
                1024: {
                  slidesPerView: "auto",
                  spaceBetween: 15,
                  pagination: false,
                },
              }}
              ref={sliderRef}
            >
              {items.map((item, index) => (
                <SwiperSlide key={index} className="gallery-card">
                  <div className="ratio ratio-1x1 bg-light rounded-5">
                    <img
                      className="h-100 w-100 object-fit-cover rounded-5"
                      src={item.imageUrl}
                      alt="..."
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GallerySection;
