import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "antd";

import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import ArrowRightIcon from "../icons/ArrowRightIcon";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../../../src/lottianimations/thanksanimation.json";
import '../../pages/Petition/petition.css'
export default function ThanksModal({ openModal, setOpenModal }) {
  
  const handleCancel = () => {
    setOpenModal(false);
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };


  return (
    <>
      <Modal
        centered
        open={openModal}
        footer={null}
        width={800}
        closeIcon={<CloseCircleOutlined style={{ color: "#005BAA" }} />}
        //  onOk={() => setOpenModal(false)}
        onCancel={handleCancel}
      >
        <div >
          <div className="thanksmain" style={{background: "url(/assets/images/thanksimg.png)"}}>
          <div className="thanksmain1">
            <Lottie options={defaultOptions} height={250} width={250} />
          </div>
        
          </div>
          <div>
          <div style={{marginTop: "-10rem"}} className="d-flex flex-column justify-content-center align-items-center">
            <p className="fs-2">Thank You!</p>
            <p className="fs-5">Thanks you for Signing This Petition</p>
                <Form.Item>
                  <Button
                    onClick={() => {
                      setOpenModal(false);
                    }}
                    className="surveybtn1 btn btn-danger border-black border-1 rounded-pill fs-5 px-4 py-3 d-inline-flex align-items-center gap-3"
                  >
                    {" "}
                    Continue
                    <span className="icon-arrow">
                      <ArrowRightIcon />
                    </span>
                  </Button>
                </Form.Item>
              </div>
              <div style={{marginTop: "-16rem"}} className="d-flex justify-content-end">
                <img className="modalimagethanks" width={340} height={250} src = "\assets\images\thanksimage2.png" />

              </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
