import React from 'react'

export default function SignThisPetitionSection() {
  return (
    <div>
        <section>
        <div
        className="container margin-top-1 mt-5"
        data-aos="fade-left"
        data-aos-duration="1400"
      >
        {/* <p className="fs-5 mb-5 pb-lg-4 sub-color">
          Started: 24 September 2023
        </p> */}
        <h1  className="mb-3 heading-color fw-medium display-6">
        <a>Sign the Petition </a>  
        </h1>
        <i className="d-block mb-3 text-18px fw-medium light-color">
        <a target='_blank' href='https://www.change.org/p/aawaz-advocating-for-equitable-education-every-child-s-right?recruiter=1317200030&recruited_by_id=56796990-5ad7-11ee-83ae-39517c4dd7aa&utm_source=share_petition&utm_campaign=share_for_starters_page&utm_medium=copylink' style={{color: "blue", cursor: "pointer"}}>Click here to sign</a>
        </i>
        <p className="mb-0 fs-5 lh-lg">
          Welcome to the "Advocating for Equitable Education - Every Child's
          Right" petition, an endeavor by our passionate student group, Aawaz.
          We firmly believe that education is a fundamental right that should be
          universally accessible to every child. In Pakistan and beyond, many
          children face barriers to education, whether due to poverty,
          discrimination, or limited resources. This petition seeks to raise
          awareness about the critical issue of education accessibility and
          advocate for concerted efforts to ensure that every child receives a
          quality education. By signing this petition, you're joining our
          collective voice in urging policymakers, educational institutions,
          communities, and stakeholders to prioritize accessible and inclusive
          education. We call for increased investment in educational
          infrastructure, teacher training, and initiatives that combat poverty
          and discrimination, all with the aim of providing a conducive learning
          environment for every child. Together, let's strive to empower every
          child with the tool of education, fostering a brighter and more
          equitable future for all. Sign this petition to be a part of the
          movement for educational equality and to make a real difference in the
          lives of countless children. Let's amplify our Aawaz for change!
        </p>
      </div>
        </section>
    </div>
  )
}
