import React from "react";

const EducationSection = () => {
  return (
    <section className="py-4 container-full mx-auto">
      <div className="container">
    
      </div>
      <div className="ratio ratio-21x9">
        <img
          data-aos="fade-in"
          data-aos-duration="1300"
          className="object-fit-cover"
          src="/assets/images/education-image.png"
          alt=".."
        />
      </div>
    </section>
  );
};

export default EducationSection;
