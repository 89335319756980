import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Footer from "./components/globals/Footer";
import Header from "./components/globals/Header";
import Survey from "./pages/Survey/Survey";
import Petition from "./pages/Petition/Petition";
import Contact from "./pages/Contact/Contact";
import OurInitiative from "./pages/Contact/OurInitiative";
import OurTeams from "./pages/Contact/OurTeams";

const AppRouter = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

const AppContent = () => {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div style = {{overflowX: "hidden"}}className="flex-fill">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/survey" element={<Survey />} />
          <Route path="/start-petition" element={<Petition />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/our-initiative" element={<OurInitiative />} />
          <Route path="/our-teams" element={<OurTeams />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default AppRouter;
