import React, { useRef, useState } from "react";
import "../Petition/petition.css";
import { Input } from "antd";
import Lottie from "react-lottie";
import animationData from "../../../src/lottianimations/petitionanimation.json";
import ArrowRightIcon from "../../components/icons/ArrowRightIcon";
import GallerySection from "../../components/sections/GallerySection";
import ThanksModal from "../../components/Modals/ThanksModal";
import emailjs from "@emailjs/browser";
import InstagramIcon from "../../components/icons/InstagramIcon";
export default function Contact() {
  const [openModal, setOpenModal] = useState(false);
  const [state, setState] = useState({
    name: "",
    email: "",
    mob: null,
    subject: "",
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const form = useRef("");
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("gg", form.current);
    emailjs
      .sendForm(
        "service_9t506wc",
        "template_p4kby9r",
        form.current,
        "SptxrcNCKzPHsEHJl"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent!");
          alert("Email Sent!");
        },
        (error) => {
          console.log(error.text);
          console.log("error sending message, try again!");
        }
      );
  };
  return (
    <div>
      <div
        style={{
          backgroundImage: "url(/assets/images/contactmainimage.png)",
        }}
        className="cmainimage"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignItems: "center",
          }}
        >
          <h1 className="mb-4 mt-5 display-4 fw-semibold text-white">
            Contact Us
          </h1>
          <h2 className="mb-0 text-center textcontact  display-9 fw-medium text-white">
            Feel free to get in touch with us at any time. our dedicated team is
            here and eager to assist you.
          </h2>
        </div>
      </div>
      <div className="cmiddiv shadow-lg ">
        <form ref={form} onSubmit={handleSubmit}>
          <div className="d-flex justify-content-evenly flex-wrap">
            <div
              style={{ flexGrow: "1" }}
              className="d-flex flex-column mt-5 ms-3 me-3 "
            >
              <input
                name="name"
                className="mt-4 me-2 w-100 contact-input"
                // style={{ width: "400px" }}
                placeholder="Full Name"
              />

              <input
                name="mob"
                className="mt-4 me-2 w-100 contact-input"
                // style={{ width: "400px" }}
                placeholder="Mobile number"
              />
              <input
                name="user_email"
                className="mt-4 me-2 w-100 contact-input"
                // style={{ width: "400px" }}
                placeholder="Email"
                type="email"
              />
              <textarea
              
                name="user_message"
                className="mt-4 me-2 w-100 contact-text-area"
                // style={{ width: "400px" }}
                placeholder="Subject"
              />
            </div>

            <div
              className="pdownimage1"
              style={{
                backgroundImage: "url(/assets/images/cimagemid.png)",
                flexGrow: "2",
              }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <p className="fw-semibold text-white fs-2 mt-5">
                  Drop Us a Message
                </p>
                <p className=" text-white fs-6 text-center w-50">
                  Reach out and connect with us by dropping a message
                </p>
                <a
                  href="https://www.instagram.com/ilmkiiaawaz/?igsh=MTVlNnQzeDZxYmJjZw%3D%3D"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link mt-4"
                >
                  <InstagramIcon />
                  <span className="ms-3">Instagram</span>
                </a>
                <div className="mt-5 pt-lg-5">
                  <div className="btn btn-danger border-black border-2 rounded-pill fs-5 px-4 py-3 d-inline-flex align-items-center gap-3">
                    <button
                      style={{ background: "transparent", border: "none" , color: "white"}}
                      type="submit"
                    >
                      Send now
                    </button>
                    <span className="icon-arrow">
                      <ArrowRightIcon />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {openModal ? (
        <ThanksModal openModal={openModal} setOpenModal={setOpenModal} />
      ) : null}
    </div>
  );
}
