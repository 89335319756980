import React, { useState } from "react";
import "../Petition/petition.css";
import { Input } from "antd";
import Lottie from "react-lottie";
import animationData from "../../../src/lottianimations/petitionanimation.json";
import ArrowRightIcon from "../../components/icons/ArrowRightIcon";
import GallerySection from "../../components/sections/GallerySection";
import ThanksModal from "../../components/Modals/ThanksModal";
export default function OurInitiative() {
  const [openModal, setOpenModal] = useState(false);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div>
      <div
        style={{
          backgroundImage: "url(/assets/images/contactmainimage.png)", height: "40vh"
        }}
        className="cmainimage"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignItems: "center",
          }}
        >
          <h1 style={{marginTop: "5rem"}}  className=" display-4 fw-semibold text-white">
            Our Initiatives
          </h1>
       
        </div>
      </div>
      <section>
        <div
          className="container margin-top-1 mt-5 mb-5"
          data-aos="zoom-in"
          data-aos-duration="1300"
        >
          <h1 className="mb-3 heading-color fw-medium display-6">
            Our Initiatives
          </h1>
          <p className="mb-0 fs-5 lh-lg mt-5">
            We embody the spirit of being "Ilm ki Aawaz." Comprising a small yet
            passionate group of students, Our collective mission is to make
            education more accessible and inclusive in Pakistan. Aawaz strives
            to break barriers, providing accessible and inclusive learning
            opportunities to all. With dedication and vigor, we advocate for
            educational equity, aiming to amplify the voices of learners across
            the nation.
          </p>
          <p className="mb-0 fs-5 lh-lg">
            Through collaborative efforts and innovative approaches, we envision
            a future where knowledge becomes a shared right, empowering
            individuals and propelling Pakistan towards a brighter, more
            educated tomorrow.
          </p>
        </div>
      </section>

      {openModal ? (
        <ThanksModal openModal={openModal} setOpenModal={setOpenModal} />
      ) : null}
    </div>
  );
}
