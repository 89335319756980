import React, { useState } from "react";
import "./petition.css";
import { Input } from "antd";
import Lottie from "react-lottie";
import animationData from "../../../src/lottianimations/petitionanimation.json";
import ArrowRightIcon from "../../components/icons/ArrowRightIcon";
import GallerySection from "../../components/sections/GallerySection";
import ThanksModal from "../../components/Modals/ThanksModal";
export default function Petition() {
  const [openModal, setOpenModal] = useState(false);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div>
      <div
        style={{
          backgroundImage: "url(/assets/images/pmainimage.png)",
        }}
        className="pmainimage shadow-lg"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignItems: "center",
          }}
        >
          <h1 className="mb-4 mt-5 display-3 fw-semibold text-white">
            Start a Petition
          </h1>
          <h2 className="mb-0 ms-2 display-6 fw-medium text-white">
            Join the Movement for Improved Education Access
          </h2>
        </div>
      </div>
      <div className="pmiddiv">
        <div className="d-flex justify-content-evenly flex-wrap">
          <div className="d-flex flex-column  mt-5 ms-3">
            <Input
              className="mt-4 w-100"
              // style={{ width: "475px" }}
              placeholder="Full Name"
            />

            <Input
              className="mt-4 w-100"
              // style={{ width: "475px" }}
              placeholder="Mobile number"
            />
            <Input
              className="mt-4 w-100"
              // style={{ width: "475px" }}
              placeholder="Email"
            />
          </div>
          <div  >
            <Lottie options={defaultOptions} className = "lottieanimate1" />
          </div>
        </div>
        <div
          className="pdownimage"
          style={{ backgroundImage: "url(/assets/images/pdownimage.png)" }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <p className="fw-semibold text-white fs-2 mt-5">Empower Change</p>
            <p className=" text-white fs-6 ms-2">
              Your Voice Matters in Shaping Better Education Access
            </p>

            <div className="mt-5 pt-lg-5">
              <div
                onClick={() => {
                  setOpenModal(true);
                }}
                className="btn btn-danger border-black border-2 rounded-pill fs-5 px-4 py-3 d-inline-flex align-items-center gap-3"
              >
                <span>Sign this Petition</span>
                <span className="icon-arrow">
                  <ArrowRightIcon />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <GallerySection />
      </div>
      {openModal ? (
        <ThanksModal openModal={openModal} setOpenModal={setOpenModal} />
      ) : null}
    </div>
  );
}
