import React from "react";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const items = [
  {
    title: "250 Supporters",
    time: "2 hours ago",
  },
  {
    title: "200 Supporters",
    time: "3 months ago",
  },
  {
    title: "100 Supporters",
    time: "4 months ago",
  },
  {
    title: "AAWAZ started this Petition",
    time: "5 months ago",
  },
  {
    title: "AAWAZ started this Petition",
    time: "5 months ago",
  },
  {
    title: "AAWAZ started this Petition",
    time: "5 months ago",
  },
  {
    title: "AAWAZ started this Petition",
    time: "5 months ago",
  },
  {
    title: "AAWAZ started this Petition",
    time: "5 months ago",
  },
];

const UpdatesSection = () => {
  return (
    <section className="py-4 overflow-hidden">
      <div className="container">
        <div
          className="text-center mb-5"
          data-aos="zoom-in"
          data-aos-duration="1300"
        >
          <h1 className="mb-1 heading-color fw-medium display-6">Contributions</h1>
          {/* <p className="mb-0 fs-5">Check Overall Updates History</p> */}
        </div>
        <div className="max-w-2 mx-auto">
        <div
          className=" pb-3"
          data-aos="zoom-in"
          data-aos-duration="1300"
        >
          <h1 className="mb-4 mt-2 heading-color fw-medium display-6">
          What we’ve accomplished:
          </h1>
          <ul>
          <li className="mb-4  fs-5">
          Conducted successful surveys, petition and interviews both online and in real life!
          </li>
          <li className="mb-4 fs-5">
          Initiated a book drive for less privelleged students in our own school
          </li>
          <li className="mb-4 fs-5">
          Spread information about the lack of the right to education 
          </li>
          <li className="mb-4 fs-5">
          Collaborated with major NGOs such as Sunbeams School to further spread our agenda
          </li>
          <li className="mb-4 fs-5">
          Helped out and did activities with students at NGOs such as the school ran by Mehreen Azmat
          </li>
          <li className="mb-4 fs-5">
          Utilized social media platforms such as Instagram to further our initiative
          </li>
          </ul>
  
        </div>
          {/* <div className="row">
            <div
              className="col-12 col-md-5 col-lg-5"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div>
                <Swiper
                  spaceBetween={10}
                  slidesPerView={4}
                  direction={"vertical"}
                  autoplay={{ delay: 4000 }}
                  modules={[Autoplay]}
                  loop="true"
                  className="update-swiper"
                >
                  {items.map((item, index) => (
                    <SwiperSlide key={index}>
                      <div className="d-flex gap-4 align-items-center">
                        <div className="number-box">0{index + 1}</div>
                        <div>
                          <h5 className="mb-1 heading-color fw-semibold">
                            {item.title}
                          </h5>
                          <p className="mb-0 text-18px light-color-2">
                            2 hours ago
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            <div
              className="col-12 col-md-7 col-lg-7"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <img
                className="h-100 w-100"
                src="/assets/images/update-image.png"
                alt="..."
              />
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default UpdatesSection;
