import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "antd";

import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import ArrowRightIcon from "../icons/ArrowRightIcon";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../../../src/lottianimations/deleteanimate.json";

export default function TrashModal({ openModal, setOpenModal }) {
  
  const handleCancel = () => {
    setOpenModal(false);
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

 setTimeout(()=>{
setOpenModal(false)
 },6000)
  return (
    <>
      <Modal
        centered
        open={openModal}
        footer={null}
        closeIcon={<CloseCircleOutlined style={{ color: "#005BAA" }} />}
        //  onOk={() => setOpenModal(false)}
        onCancel={handleCancel}
      >
        <div className="d-flex justify-content-evenly">
          <div>
          <div>
            <Lottie options={defaultOptions} height={250} width={250} />
          </div>
          <p className="heading-color fw-medium display-6 mt-4">
              Clearing All Fields...
            </p>
            {/* <p className="heading-color fw-medium display-6 mt-4">
              Clear Form?
            </p>
            <p className="fs-5">
              This will remove your answers from all questions, and cannot be
              undone.
            </p>
            <div className="d-flex justify-content-end">
              <div>
                <Form.Item>
                  <Button
                    onClick={() => {
                      setOpenModal(false);
                    }}
                    className="surveybtn1 btn btn-danger border-black border-1 rounded-pill fs-5 px-4 py-3 d-inline-flex align-items-center gap-3"
                  >
                    {" "}
                    Cancel
                    <span className="icon-arrow">
                      <ArrowRightIcon />
                    </span>
                  </Button>
                </Form.Item>
              </div>
              <div className="ms-4">
          
              </div>
            </div> */}
          </div>
        </div>
      </Modal>
    </>
  );
}
